import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import ArrayOfImages from "../Components/ArrayOfImages";
import {
  EnvelopeIcon,
  FireIcon,
  GlobeAltIcon,
  PowerIcon,
} from "@heroicons/react/24/outline";
import {
  Cog8ToothIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import aswaniLogo from "../assets/Aswani.png";
import bhandariLogo from "../assets/bhandariLogo.jpg";
import geraLogo from "../assets/geraLogo.webp";
import kohinoorLogo from "../assets/kohinoorLogo.png";
import goelGangaLogo from "../assets/GoelGanga.jpg";
import { Copyright, Facebook } from "lucide-react";
import rojImage from "../assets/ROJ.jpg";
import riImage from "../assets/RI.jpg";
import kohinoorVivaPixelImage from "../assets/KohinoorVivaPixel.jpg";
import geraImage from "../assets/GeraImg.webp";
import kothariInternationalSchoolKharadi from "../assets/Kothari_International_School_Kharadi.jpg";
import Footer from "../Components/Footer";
import Features from "../Components/Features";
import WhyChooseUs from "../Components/WhyChooseUs";

const features = [
  {
    name: "Mechanical",
    description:
      "Ventilation, Air-Conditioning, Heating, CFD-Analysis, Disctrict Cooling, Central Plant Design, VRF System-Design",
    icon: Cog8ToothIcon,
    image: rojImage
  },
  {
    name: "Plumbing",
    description:
      "Water Treatment, System Design, Hot & Cold Water Supply, Drainage System, Waste-Water Treatment, Sewage/Effluent Treatment Plan",
    icon: WrenchScrewdriverIcon,
    image: riImage
  },
  {
    name: "Fire Fighting",
    description:
      "Fire Hydrants & water piping, Sprinkler layout, Fire Alarm, Public Address System",
    icon: FireIcon,
    image: kohinoorVivaPixelImage
  },
  {
    name: "Associated Services",
    description:
      "MEP Peer Review, Infrastructure Design & Planning, Building Information Modeling(BMI), Value Engineering, Energy Audits, Quality Audits, Fire & Life Safety Consulting & Audits",
    icon: GlobeAltIcon,
    image: geraImage
  },
  {
    name: "Electrical",
    description:
      "HT & LT Power Distribution, Lighting, Emergency Power Backup, Earthing & Lighting Protection, Voice/Data Services, Extra Low Voltage & Integrated Building Management Systems, Security System",
    icon: PowerIcon,
    image: kothariInternationalSchoolKharadi
  },
];

const clients = [
  {
    name: "Aswani",
    logo: aswaniLogo,
  },
  {
    name: "Bhandari",
    logo: bhandariLogo,
  },
  {
    name: "Gera",
    logo: geraLogo,
  },
  {
    name: "Kohinoor",
    logo: kohinoorLogo,
  },
  {
    name: "Goel Ganga",
    logo: goelGangaLogo,
  },
];

export default function Home() {
  return (
    <div className="relative homepage-container">
      <Navbar homePage={true} />
      <ArrayOfImages homepage={true} title="Home Page" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 max-w-7xl mx-auto my-20 items-center lg:items-start">
        <div className="flex flex-col justify-center items-center lg:items-start gap-3 max-w-md p-6">
          <h2 className="text-2xl text-center lg:text-left font-bold">
            Convenient MEP Design Consultants Pune LLP
          </h2>
          <p className="font-light text-gray-500">Pinnacle Of Satisfaction</p>
        </div>
        <div className="flex flex-col justify-center items-center lg:items-start gap-10 p-6">
          <div className="flex flex-col justify-center items-start gap-3 max-w-xl">
            <p className="font-light text-gray-500">
              The successful design deliverables to client's satisfaction
              through our expertise is our main Vision and Mission. As an
              organization we value our commitments to Honesty, Integrity and
              value remain the foremost. Our adherence to the standards set by
              the national and International bodies with respect to the MEP
              services are of prime important."Client Satisfaction and
              Appreciation is our Achievement"
            </p>
          </div>
          <div className="flex flex-col justify-center items-start gap-3 max-w-xl">
            <p className="font-light text-gray-500">
              We are a dedicated team of engineers with 20 years experience. We
              are a combination of seasoned professionals as well as young
              Engineers. Promoters have served leading Companies and MNC's with
              wide experience in all discipline of MEP. We are one of the rare
              groups where all disciplines of MEP are in House and Individual
              Promoters are Specialist in their respective Discipline
            </p>
          </div>
        </div>
      </div>
      <hr className="text-gray-700 mx-10" />
      <div className="flex flex-col justify-center items-center gap-10 pt-24 w-full">
        <h1 className="font-bold sm:text-2xl">Services we offer</h1>
        <Features />
      </div>
      <hr className="text-gray-700 mx-10" />
      <div className="flex justify-center items-center mt-24">
        <h2 className="font-bold sm:text-2xl">Some of Our Prestigious Clients</h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-20 p-20 max-w-7xl mx-auto">
        {clients.map((client, index) => (
          <img
            className="h-10"
            key={index}
            src={client.logo}
            alt={client.name}
          />
        ))}
      </div>
      <WhyChooseUs />
      <Footer />
    </div>
  );
}
