import React from "react";
import aswaniLogo from "../assets/Aswani.png";
import bhandariLogo from "../assets/bhandariLogo.jpg";
import geraLogo from "../assets/geraLogo.webp";
import kohinoorLogo from "../assets/kohinoorLogo.png";
import goelGangaLogo from "../assets/GoelGanga.jpg";
import phoenix from "../assets/Phoenix.png";
import MantraMonarch from "../assets/Mantra MONARCHLOGO.jpeg";
import rohan from "../assets/Rohan.jpg";
import symbiosis from "../assets/Symbiosis.jpg";
import t500x300 from "../assets/t500300.jpg";
import yashada from "../assets/yashada-logo.jpg";
import archron from "../assets/archron.jpg";
import ankurAssociates from "../assets/ankur-associates-logo.png";
import advantageArchitec from "../assets/advantage-architec-logow.jpeg";
import analogo from "../assets/ANAlogo.jpg";
import atlogo from "../assets/AT.png";
import cubix from "../assets/Cubix.png";
import venktlogo from "../assets/venkataramanan_associates_logo.jpg";
import laxmanThite from "../assets/Laxman Thite.png";
import desk from "../assets/Desk.jpeg";
import pgPatki from "../assets/PG Patki.jpg";
import vkalogo from "../assets/VK-a.png";
import Navbar from "./Navbar";
import ArrayOfImages from "../Components/ArrayOfImages";
import WhyChooseUs from "../Components/WhyChooseUs";
import Footer from "../Components/Footer";

const clients = [
  {
    name: "Gera",
    logo: geraLogo,
  },
  {
    name: "Kohinoor",
    logo: kohinoorLogo,
  },
  {
    name: "Goel Ganga",
    logo: goelGangaLogo,
  },
  {
    name: "Phoenix",
    logo: phoenix,
  },
  {
    name: "Mantra Monarch",
    logo: MantraMonarch,
  },
  {
    name: "Aswani",
    logo: aswaniLogo,
  },
  {
    name: "Bhandari",
    logo: bhandariLogo,
  },
  {
    name: "Rohan",
    logo: rohan,
  },
  {
    name: "Symbiosis",
    logo: symbiosis,
  },
  {
    name: "t_500x300",
    logo: t500x300,
  },
  {
    name: "Yashada",
    logo: yashada,
  },
];

const architects = [
  {
    name: "VK a",
    logo: vkalogo,
  },
  {
    name: "Cubix",
    logo: cubix,
  },
  {
    name: "AT Logo",
    logo: atlogo,
  },
  {
    name: "ANA Logo",
    logo: analogo,
  },
  {
    name: "Ankur Associates",
    logo: ankurAssociates,
  },
  {
    name: "Advantage Architec",
    logo: advantageArchitec,
  },
  {
    name: "Venkt Logo",
    logo: venktlogo,
  },
  {
    name: "Laxman Thite",
    logo: laxmanThite,
  },
  {
    name: "Desk",
    logo: desk,
  },
  {
    name: "PG Patki",
    logo: pgPatki,
  },
  {
    name: "Archron",
    logo: archron,
  },
];

const Clients = () => {
  return (
    <div className="clientsPage-container">
      <Navbar homePage={false} />
      <ArrayOfImages homepage={false} title="Clients" />
      <div className="flex justify-center items-center mt-24">
        <h2 className="font-bold sm:text-2xl">Our Prestigious Clients</h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-20 p-20 max-w-7xl mx-auto">
        {clients.map((client, index) => (
          <img
            className="h-10"
            key={index}
            src={client.logo}
            alt={client.name}
          />
        ))}
      </div>
      <hr className="text-gray-700 mx-10" />
      <div className="flex justify-center items-center mt-24">
        <h2 className="font-bold sm:text-2xl">Our Architects</h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-20 p-20 max-w-7xl mx-auto">
        {architects.map((architect, index) => (
          <img
            className="h-10"
            key={index}
            src={architect.logo}
            alt={architect.name}
          />
        ))}
      </div>
      <WhyChooseUs />
      <Footer />
    </div>
  );
};

export default Clients;
