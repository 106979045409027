import React from "react";
import {
  EnvelopeIcon,
  FireIcon,
  GlobeAltIcon,
  PowerIcon,
} from "@heroicons/react/24/outline";
import {
  Cog8ToothIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import electrical from "../assets/electrical.png";
import fireFighting from "../assets/fire.png";
import mechanical from "../assets/mechanical.png";
import plumbing from "../assets/plumbing.png";
import kothariInternationalSchoolKharadi from "../assets/Kothari_International_School_Kharadi.jpg";

const features = [
  {
    name: "Mechanical",
    description:
      "Ventilation, Air-Conditioning, Heating, CFD-Analysis, Disctrict Cooling, Central Plant Design, VRF System-Design",
    icon: Cog8ToothIcon,
    image: mechanical,
  },
  {
    name: "Plumbing",
    description:
      "Water Treatment, System Design, Hot & Cold Water Supply, Drainage System, Waste-Water Treatment, Sewage/Effluent Treatment Plan",
    icon: WrenchScrewdriverIcon,
    image: plumbing,
  },
  {
    name: "Fire Fighting",
    description:
      "Fire Hydrants & water piping, Sprinkler layout, Fire Alarm, Public Address System",
    icon: FireIcon,
    image: fireFighting,
  },
  {
    name: "Electrical",
    description:
      "HT & LT Power Distribution, Lighting, Emergency Power Backup, Earthing & Lighting Protection, Voice/Data Services, Extra Low Voltage & Integrated Building Management Systems, Security System",
    icon: PowerIcon,
    image: electrical,
  },
];

const Features = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 max-w-7xl mx-auto mb-20 p-10">
      {features.map((feature, index) => {
        return (
          <div
            key={index}
            className="flex flex-col-reverse md:flex-row justify-center md:justify-start items-start bg-white rounded-md shadow-sm border-2"
          >
            <div className="flex flex-col gap-5 p-4">
              <div className="flex justify-start items-center gap-3">
                <feature.icon className="h-6 text-gray-600" />
                <p className="font-bold">{feature.name}</p>
              </div>
              <div className="max-w-sm md:max-w-lg">
                <p className="text-xs text-left text-gray-500 font-light">
                  {feature.description}
                </p>
              </div>
            </div>
            <img
              className="hidden lg:block w-64 h-40 rounded-tr-md rounded-br-md object-cover md:object-fill"
              src={feature.image}
              alt={feature.name}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Features;
