import React, { useEffect, useState } from "react";
import bgOne from "../assets/bgOne.png.png";
import bgTwo from "../assets/bgTwo.png.jpg";
import bgThree from "../assets/bgThree.png.jpg";
import bgFour from "../assets/bgFour.png.jpg";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const arrayOfImages = [
  {
    name: "bg-one",
    image: bgOne,
    description: "We shape our buildings, thereafter they shape us",
  },
  {
    name: "bg-two",
    image: bgTwo,
    description: "Complex Design Solutions made easy",
  },
  {
    name: "bg-three",
    image: bgThree,
    description:
      "Good buildings come from good people, and all problems are solved by good design",
  },
  {
    name: "bg-four",
    image: bgFour,
    description: "Emerging Future through Design Sustainability",
  },
];

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "100vh",
};

const buttonStyle = {
  width: "30px",
  background: "none",
  border: "0px",
  display: "none",
};

const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
        <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
        <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
      </svg>
    </button>
  ),
};

const ArrayOfImages = ({ homepage, title }) => {
  return (
    <div>
      {homepage && (
        <Fade {...properties} duration={5000} transitionDuration={500}>
          {arrayOfImages.map((image, index) => (
            <div key={index}>
              <div
                style={{ ...divStyle, backgroundImage: `url(${image.image})` }}
              >
                <span className="max-w-2xl text-4xl text-center text-white font-semibold p-4">
                  {image.description}
                </span>
              </div>
            </div>
          ))}
        </Fade>
      )}
      {!homepage && (
        <div className="flex justify-center items-center bg-one bg-cover bg-no-repeat h-screen">
          <h1 className="max-w-2xl text-5xl text-center text-white font-bold">
            {title}
          </h1>
        </div>
      )}
    </div>
  );
};

export default ArrayOfImages;
