import React from "react";

const reasons = [
  "Detailed designing to the level of execution by the contractor",
  "Planned coordination with various agencies like architect, client, structural, and the site execution team",
  "Since total MEP is Inhouse it becomes easy for the architect and client to coordinate and valuable time is saved",
  "Value engineering is suggested by us which helps the client to save on its allocated budget",
];

const WhyChooseUs = () => {
  return (
    <div className="bg-gray-100 flex flex-col justify-center items-center gap-10 pt-20 w-full">
      <h2 className="font-bold sm:text-2xl">"Why Convenient"</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 max-w-7xl mx-auto mb-20 p-10">
        {reasons.map((reason, index) => {
          return <p className="text-gray-700" key={index}>{reason}</p>;
        })}
      </div>
    </div>
  );
};

export default WhyChooseUs;
