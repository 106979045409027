import React from "react";
import Navbar from "./Navbar";
import ArrayOfImages from "../Components/ArrayOfImages";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Copyright, Facebook } from "lucide-react";

const Contact = () => {
  return (
    <div className="contactPage-container">
      <Navbar homePage={false} />
      <ArrayOfImages homepage={false} title="Contact" />
      <div className="grid grid-cols-1 gap-32 max-w-7xl mx-auto my-20 items-center p-20">
        <div className="flex flex-col justify-center items-center gap-16">
          <h1 className="text-2xl font-bold">Our Office</h1>
          <div className="flex flex-col justify-center items-center gap-5">
            <p className="text-lg font-semibold">Pune</p>
            <div className="flex flex-col gap-3">
            <p className="text-gray-700 font-light max-w-sm text-center lg:text-left">
              103, Radha Apartments, Pashan Road, Bavdhan, Pune 411021
            </p>
            <p className="text-gray-700 font-light max-w-sm text-center lg:text-left">
              Ph.: +91-9527441988, +91-9860359004
            </p>
            </div>
          </div>
        </div>
        <hr className="text-gray-700 mx-10" />
        <div className="flex flex-col justify-center items-center gap-16">
          <h1 className="text-2xl font-bold">Get In Touch</h1>
          <div className="flex flex-col md:flex-row justify-center items-center gap-10">
            <div className="flex flex-col justify-center items-center gap-3">
              <EnvelopeIcon className="h-6 text-gray-700" />
              <a
                href="mailto:admin@cmep.com"
                className="font-light text-gray-700"
              >
                admin@cmeppune.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
