import React from "react";
import Navbar from "./Navbar";
import Footer from "../Components/Footer";
import ArrayOfImages from "../Components/ArrayOfImages";
import WhyChooseUs from "../Components/WhyChooseUs";

const teamMembers = [
  {
    name: "Mr Nitin Bhale",
    info: "Mr. Bhale is an Electrical Engineering Graduate from, SGSITS, Indore, He has also done Post Graduate Diploma in Telecommunication, Helsinki, Finland. Certified Course in interior and exterior lighting Design, Mumbai With over 32 years of experience in design, consultancy and execution of electrical works. He is Expertise in Electrical Design of Malls, Hotels, Hospitals, High Rise Residential, Industries, Pharma, Specialize Applications & Infrastructure Projects.",
  },
  {
    name: "Mr Rahul Teware",
    info: "Mr. Rahul  is an Electrical Engineering Graduate from Pune University.  He is having 15 Years of Experience in Electrical concept designing, schematic designing, Execution, detailed design, Life Safety and Security, ELV, tender documentation. He is well versed with the nuances of Consultancy Industry",
  },
  {
    name: "Mr Ashish Khoti",
    info: "Mr. Ashish is an Mechanical Engineer. 14 Years of Experience in HVAC concept designing, schematic designing, detailed design, tender documentation. He is Expertise in HVAC Design of Malls, Hotels, Hospitals, Commercial & IT Buildings",
  },
  {
    name: "Mr Krushna Kumbhar",
    info: "Mr. Krushna is an Electrical Engineering Graduate . He is having 12 Years of Experience in Electrical concept designing, schematic designing, Execution, detailed design, Life Safety and Security, ELV, tender documentation. He is proficient in MEP Coordination and has a sound knowledge of site activities."
  },
  {
    name: "Mr Madhav Mairal",
    info: "Mr. Mairal is a Graduate in Electronics and a strong background of Finance Sector with an experience of 29 years in diversified field of manufacturing of control panels, turnkey electrical contracting and CFO in Risk Management Company. Also has a flair and passion for Business Development.",
  },
  {
    name: "Mr Ajay Madam",
    info: "Mr. Ajay is a Diploma Mechanical Engineering Graduate- With over 6 years of experience in the PHE & Fire Fighting, Design & Execution. He has excellent background in concept designing, schematic designing , detailed design, tender documentation. He is Expertise in PHE & FF Design of Malls, Hotels, Hospitals, High Rise Residential,Infrastructure Projects.",
  },
];

const About = () => {
  return (
    <div className="aboutPage-container z-10">
      <Navbar homePage={false} />
      <ArrayOfImages homepage={false} title="About" />
      <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      <div className="flex flex-col justify-center items-center gap-10 pt-24 mt-10 w-full">
        <h1 className="font-bold sm:text-4xl">Meet Our Team</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 max-w-7xl mx-auto mb-20 p-10">
          {teamMembers.map((member, index) => {
            return (
              <div key={index} className="flex justify-start items-start bg-white rounded-md shadow-sm border-2">
                <div className="flex flex-col gap-5 p-4">
                  <div className="flex justify-start items-center gap-3">
                    <p className="font-bold">{member.name}</p>
                  </div>
                  <div className="max-w-sm md:max-w-lg">
                    <p className="text-xs text-left text-gray-500 font-light">
                      {member.info}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <WhyChooseUs />
      <Footer />
    </div>
  );
};

export default About;
