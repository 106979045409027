import React, { useState } from "react";
import Navbar from "./Navbar";
import kothariSchool from "../assets/Kothari_International_School_Kharadi.jpg";
import bishopsSchool from "../assets/BishopsSchoolKalyaniNagar.jpg";
import pvg from "../assets/PVG.jpg";
import ovella from "../assets/ovella.jpg";
import platinumCapital from "../assets/PlatinumCapital.jpg";
import geraImg from "../assets/GeraImg.webp";
import platinumKharadi from "../assets/PlatinumTowersKharadi.jpg";
import sapraCommercial from "../assets/SapraCommercialKoregaonparkNXT.jpg";
import symbiosisInstitute from "../assets/SymbiosisInstituteNagpurCampus.jpg";
import vedritiOne from "../assets/vedriti1.webp";
import res1 from "../assets/Miravet-District-Pune-.jpg";
import res2 from "../assets/Kohinoor Viva Pixel.jpg";
import res3 from "../assets/Ganga-Serio-Pune-.jpg";
import res4 from "../assets/-Mantra-Monarch-Pune-.jpg";
import res5 from "../assets/-Neco-Beaumont-Pune-.jpg";
import res6 from "../assets/-Rohan-Ipsita-Pune-.jpg";
import res7 from "../assets/-Rohan-Prathama-Pune-.jpg";
import res8 from "../assets/ROJ.jpg";
import res9 from "../assets/Swadesha.webp";
import res10 from "../assets/Yashada-Windsong-Pune-.jpg";
import Footer from "../Components/Footer";
import ArrayOfImages from "../Components/ArrayOfImages";
import WhyChooseUs from "../Components/WhyChooseUs";

const commercialInstitutionalprojects = [
  {
    name: "Ovella",
    image: ovella,
    size: "35,000",
    client: "Amrit Mirpuri",
    architect: "Desk Architects",
    projectLocation: "Koregaon Park Annexe",
    scope: "MEPF",
    status: "40% Completed",
  },
  {
    name: "Platinum Capital",
    image: platinumCapital,
    size: "2,42,722",
    client: "Advani Developers",
    architect: "Desk Architects",
    projectLocation: "Kharadi, Pune",
    scope: "MEPF",
    status: "50% Completed",
  },
  {
    name: "Gera Imperium Gateway",
    image: geraImg,
    size: "12,59,000",
    client: "Gera Developments P. Ltd.",
    architect: "Ar. Avinash Nawathe (ANA)",
    projectLocation: "Kasarwadi Pune",
    scope: "MEPF",
    status: "70% Completed",
  },
  {
    name: "Platinum Towers Kharadi",
    image: platinumKharadi,
    size: "2,12,000",
    client: "Arista Developers",
    architect: "Desk Architects",
    projectLocation: "Kharadi, Pune",
    scope: "MEPF",
    status: "85% Completed",
  },
  {
    name: "Sapra Commercial Koreagaonpark NXT",
    image: sapraCommercial,
    size: "1,75,000",
    client: "Nishit Sapra",
    architect: "Cubix Architects",
    projectLocation: "Mundhwa",
    scope: "MEPF",
    status: "40% Completed",
  },
  {
    name: "Symbiosis Institute Nagpur Campus",
    image: symbiosisInstitute,
    size: "4,00,000",
    client: "Symbiosis International",
    architect: "Ar.Hafeez Contractor",
    projectLocation: "Nagpur",
    scope: "ELV Design",
    status: "Completed",
  },
  {
    name: "Vedriti",
    image: vedritiOne,
    size: "1,03,000",
    client: "Royal Infra (Surendra Pathare)",
    architect: "A&T",
    projectLocation: "Kharadi, Pune",
    scope: "MEPF",
    status: "Completed",
  },
  {
    name: "Kothari International School Kharadi",
    image: kothariSchool,
    size: "65,200",
    client: "Kothari International School",
    architect: "Internal Architect",
    projectLocation: "Kharadi",
    scope: "MEPF",
    status: "Completed",
  },
  {
    name: "Bishops School Kalyani Nagar",
    image: bishopsSchool,
    client: "Bishops School, Kalyani Nagar",
    architect: "Planedge Consultants",
    projectLocation: "Kalyani Nagar",
    scope: "External Plumbing Redesign",
    status: "Completed",
    size: "External Campus",
  },
  {
    name: "PVG Science College Pune",
    image: pvg,
    size: "45,000",
    client: "Pune Vidyarthi Gruha",
    architect: "Ar. Dilip Kale",
    projectLocation: "Parvati",
    scope: "MEPF",
    status: "25% Completed",
  },
];

const residentialProjects = [
  {
    name: "Miravet Disctrict Pune",
    image: res1,
    size: "28,30,000",
    client: "Aswani Developers",
    architect: "Ar. Prakash Kulkarni Pune",
    projectLocation: "Ravet, Pune",
    scope: "MEPF Design",
    status: "Phase 1 of 6.30 lac sq.ft.80% Completed",
  },
  {
    name: "Kohinoor Viva Pixel",
    image: res2,
    size: "7,72,000",
    client: "Kohinoor Developers",
    architect: "VK:a Architecture",
    projectLocation: "Dhanori, Pune",
    scope: "MEPF Design",
    status: "40% Completed",
  },
  {
    name: "Ganga Serio Pune",
    image: res3,
    size: "6,34,845",
    client: "Goel Ganga Developments",
    architect: "Ar. Laxman Thite",
    projectLocation: "Kharadi, Pune",
    scope: "MEPF Design",
    status: "Completed",
  },
  {
    name: "Mantra Monarch Pune",
    image: res4,
    size: "3,25,000",
    client: "Mantra Properties",
    architect: "Advantage Architects",
    projectLocation: "Balewadi, Pune",
    scope: "MEPF Design",
    status: "Completed",
  },
  {
    name: "Neco Beaumont Pune",
    image: res5,
    size: "2,25,000",
    client: "Vasupujya Teerth Home Developers",
    architect: "SDI Consultants P. Ltd. Pune",
    projectLocation: "NIBM Annexe, Pune",
    scope: "MEPF Design",
    status: "Completed",
  },
  {
    name: "Rohan Ipsita Pune",
    image: res6,
    size: "1,15,000",
    client: "Rohan Builders",
    projectLocation: "Hinjewadi, Pune",
    scope: "MEPF Design",
    status: "Completed",
  },
  {
    name: "Rohan Prathama Pune",
    image: res7,
    size: "2,20,000",
    client: "Rohan Builders",
    projectLocation: "Hinjewadi, Pune",
    scope: "MEPF Design",
    status: "Completed",
  },
  {
    name: "River of Joy, Kadamba, Goa",
    image: res8,
    size: "6,00,000",
    type: "Mixed Utility",
    status: "Completed",
    client: "Gera Devolopments P. Ltd",
    architect: "Gridlines Pune",
    scope: "MEPF Design",
    projectLocation: "Kadamba Goa",
  },
  {
    name: "Swadesha by Bhandari Associates, Moshi",
    image: res9,
    size: "5,98,810",
    client: "Bhandari Associates",
    architect: "Ar. Solespace Pune",
    projectLocation: "Moshi, Pune",
    scope: "MEPF Design",
    status: "Phase 1 Completed- Phase 2 -Ongoing",
  },
  {
    name: "Yashada Windsong Pune",
    image: res10,
    size: "3,15,000",
    client: "Vivanta Realities",
    architect: "Landmark Designs Pune",
    projectLocation: "Ravet, Pune",
    scope: "MEPF Design",
    status: "50% Completed",
  },
];

const Projects = () => {
  const [commercialInstitutional, setCommercialInstitutional] = useState(true);
  const [residential, setResidential] = useState(false);

  const toggleCommercialInstitutionalResidential = () => {
    setCommercialInstitutional(!commercialInstitutional);
    setResidential(!residential);
  };

  return (
    <div className="projectsPage-container">
      <Navbar homePage={false} />
      <ArrayOfImages homepage={false} title="Projects" />
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="flex justify-center items-center mt-32">
        <div className="flex justify-center items-center gap-20">
          <h1
            className={`font-bold sm:text-xl ${
              commercialInstitutional && "border-b-[3px] border-b-blue-500"
            } hover:cursor-pointer`}
            onClick={toggleCommercialInstitutionalResidential}
          >
            Commercial & Institutional
          </h1>
          <h1
            className={`font-bold sm:text-xl ${
              residential && "border-b-[3px] border-b-blue-500"
            } hover:cursor-pointer`}
            onClick={toggleCommercialInstitutionalResidential}
          >
            Residential
          </h1>
        </div>
      </div>
      {commercialInstitutional && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 max-w-7xl mx-auto mt-10 mb-20 p-10">
          {commercialInstitutionalprojects.map((project, index) => {
            return (
              <div
                key={index}
                className="flex flex-col justify-start items-center gap-3 bg-white rounded-sm border-2 shadow-sm max-w-md"
              >
                <img
                  className="w-96 h-96 rounded-sm object-fill"
                  src={project.image}
                  alt={project.name}
                />
                <div className="flex flex-col gap-3 m-4 px-5 w-full">
                  <p className="font-bold text-center">{project.name}</p>
                  {project.size && (
                    <p className="text-center">{project.size} sq.ft</p>
                  )}
                  <hr className="w-full" />
                  <p className="text-left"><b>Client</b> - {project?.client}</p>
                  <p className="text-left"><b>Architect</b> - {project?.architect}</p>
                  <p className="text-left"><b>Location</b> - {project?.projectLocation}</p>
                  <p className="text-left"><b>Scope of work</b> - {project?.scope}</p>
                  <p className="text-left"><b>Status</b> - {project?.status}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {residential && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 max-w-7xl mx-auto mt-10 mb-20 p-10">
          {residentialProjects.map((project, index) => {
            return (
              <div
                key={index}
                className="flex flex-col justify-start items-center gap-3 bg-white rounded-sm border-2 shadow-sm max-w-md"
              >
                <img
                  className="w-96 h-96 rounded-sm object-fill"
                  src={project.image}
                  alt={project.name}
                />
                <div className="flex flex-col gap-3 m-4 px-5 w-full">
                  <p className="font-bold text-center">{project.name}</p>
                  {project.size && (
                    <p className="text-center">{project.size} sq.ft</p>
                  )}
                  <hr className="w-full" />
                  <p className="text-left"><b>Client</b> - {project?.client}</p>
                  {project.architect && (<p className="text-left"><b>Architect</b> - {project?.architect}</p>)}
                  <p className="text-left"><b>Location</b> - {project?.projectLocation}</p>
                  <p className="text-left"><b>Scope of work</b> - {project?.scope}</p>
                  <p className="text-left"><b>Status</b> - {project?.status}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <WhyChooseUs />
      <Footer />
    </div>
  );
};

export default Projects;
