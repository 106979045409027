import { EnvelopeIcon} from '@heroicons/react/24/outline'
import React from 'react'

const Footer = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-20 p-20 bg-blue-300">
        <div className="flex flex-col md:flex-row justify-center items-center gap-10">
          <div className="flex flex-col justify-center items-center gap-3">
            <EnvelopeIcon className="h-6" color="white" />
            <a href="mailto:admin@cmep.com" className="text-white font-semibold">admin@cmeppune.com</a>
          </div>
        </div>
        <div className="flex justify-center items-center gap-3 p-3">
          <p className="text-white font-semibold">&copy; 2024 Convenient MEP Design</p>
        </div>
      </div>
  )
}

export default Footer
